/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import Skus from "./Skus"
import axios from "axios"
import Countries from "./Countries"
import Invoice from "./Invoice"
import { loadStripe } from "@stripe/stripe-js"

const ShopForm = ({ area, maxlat, minlat, maxlong, minlong }) => {

  const urlPrcing = process.env.PRICING_HOST
  const [errorMessage, hasError] = useState(null)
  const [items, setItems] = useState({ skus: [], totalPrice: 0 })
  const [country, setCountry] = useState(null)
  const [invoice, setInvoice] = useState(null)
  const [initSubmit, setInitSubmit] = useState(false)
  const [email, setEmail] = useState("");
  const [data, setData] = useState(null);
  const [vatId, setVatId] = useState(null);
  const regexEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  const regexTaxId = /^[A-Za-z]{2}[0-9]{9}$/i
  const [ visibleSkus, setVisibleSkus ] = useState(false)
  //Convert to hectares
  const areaInUnitsAmount = (area / parseInt(process.env.METERS2HECTARES)).toFixed(0)

  let stripePromise
  const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe(`${process.env.STRIPE_API_KEY}`)
    }
    return stripePromise
  }
  const { handleSubmit, register, errors } = useForm()

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
    setVisibleSkus(false)
    hasError(null)
  }
  
  const changeVatId = (event) => {
    setVatId(event.target.value);
    setVisibleSkus(false)
    hasError(null)
  }

  const handleChangeCountry = (text) => {
    setCountry(text);
    setVisibleSkus(false)
    hasError(null)
  }

  const neededInvoice = (text) => {
    text.toLowerCase() === "true" ? setInvoice(true) : setInvoice(false)
    setVisibleSkus(false)
    hasError(null)
  }

  const onSubmit = async () => {
    setInitSubmit(true)
    hasError(null)
    const url = process.env.STRIPE_SESSION_URL + "/getsession"
    const data = items.skus.map(x => ({
      amount: x.amount,
      currency: x.currency,
      name: x.name,
      customerEmail: email,
      quantity: parseInt(x.quantity),
      description:
        "Area in ha: " +
        areaInUnitsAmount +
        "\n" +
        "Max Latitude: " +
        maxlat +
        "\n" +
        "Min Latitude: " +
        minlat +
        "\n" +
        "Max Longitude: " +
        maxlong +
        "\n" +
        "Min Longitude: " +
        minlong +
        "\n" +
        "billing country: " +
        country +
        "\n" +
        "needed invoice: " +
        invoice +
        "\n" +
        "VAT ID: " +
        vatId +
        "\n",
        invoice: invoice,
        taxid: vatId,
        country: country,
      images: [x.image],
    }))
    const stripe = await getStripe()
    axios
      .post(url, data)
      .then(res => {
        switch (res.data.status) {
          case "session-created":
            stripe
              .redirectToCheckout({
                sessionId: res.data.sessionId,
              })
              .then(function(result) {
                hasError(result.error.message)
              })
            break
          default:
            hasError("Something went wrong")
        }
        setInitSubmit(false)
      })
      .catch(error => {
        if (!error.response) {
          hasError("Error network...")
        } else {
          if (error.response.data.status === "Session-create-failed") {
            hasError(error.response.data.error)
          } else {
            hasError(error.response.data.status)
          }
        }
        setInitSubmit(false)
      })
  }

  const passVariables = (items) => 
  {
    setItems(items)
  }

  const handleNestedSubmit = () => {
    hasError(null)
    handleSubmit((e) => { return null })();
    if(email !== "" && (regexEmail).test(email) === true && country !== null && invoice !== null)
    {
        setVisibleSkus(true)
        setData(null)
        const axiosData =  {
          area: area,
          country: country,
          invoice: invoice,
          email: email,
          maxlat: maxlat,
          minlat: minlat,
          maxlong: maxlong,
          minlong: minlong,
        }
        if(invoice === true)
        {
          if(vatId !== "" && (regexTaxId).test(vatId) === true)
          {
              axiosData.vatId = vatId
              axios.post(urlPrcing, axiosData)
              .then(response => {
                if(response.status === 200)
                {
                  setData(response.data)
                }
              })
              .catch(err => {
                setVisibleSkus(false)
                hasError("Please try again")
              });
          }
        }
        else
        {
          axios.post(urlPrcing, axiosData)
          .then(response => {
            if(response.status === 200)
            {
              setData(response.data)
            }
            else if(response.status === 404)
            {
            }
          })
          .catch(err => {
            setVisibleSkus(false)
            hasError("Please try again")
          });
        }
    }
    if ((regexEmail).test(email) === false || (invoice === true && (regexTaxId).test(vatId) === false)){
      setVisibleSkus(false)
      setData(null)
      hasError(null)
    }
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        Area Coordinates <br></br>
        <label key="MaxLatLabel" htmlFor="MaxLat">
          Max. Latitude
        </label>
        <input
          type="number"
          key="MaxLat"
          placeholder="Max. Latitude"
          defaultValue={maxlat}
          readOnly
          step="0.0001"
          name="MaxLatitude"
          ref={register({ required: true, max: 90, min: -90 })}
        />
        {errors.MaxLatitude && <span>This field is required</span>}
        <label key="MinLatLabel" htmlFor="MinLat">
          Min. Latitude
        </label>
        <input
          type="number"
          key="MinLat"
          placeholder="Min. Latitude"
          defaultValue={minlat}
          readOnly
          step="0.0001"
          name="MinLatitude"
          ref={register({ required: true, max: 90, min: -90 })}
        />
        {errors.MinLatitude && <span>This field is required</span>}
        <label key="MaxLonLabel" htmlFor="MaxLon">
          Max. Longitude
        </label>
        <input
          type="number"
          key="MaxLon"
          placeholder="Max. Longitude"
          defaultValue={maxlong}
          readOnly
          step="0.0001"
          name="MaxLongitude"
          ref={register({ required: true, max: 180, min: -180 })}
        />
        {errors.MaxLongitude && <span>This field is required</span>}
        <label key="MinLonLabel" htmlFor="MinLon">
          Min. Longitude
        </label>
        <input
          type="number"
          key="MinLon"
          placeholder="Min. Longitude"
          defaultValue={minlong}
          readOnly
          step="0.0001"
          name="MinLongitude"
          ref={register({ required: true, max: 180, min: -180 })}
        />
        {errors.MinLongitude && <span>This field is required</span>}
        <label key="emailLabel" htmlFor="email">
          Email
        </label>
        <input
          onChange={handleChangeEmail}
          type="email"
          name="email"
          key="email"
          ref={register({
            required: true,
            pattern: {
              value: regexEmail,
              message: "invalid email address",
            },
          })}
        />
        {errors.email ? (
          <span className={"new-line fa visible failure message"}>
            {"Invalid email address"}
          </span>
        ) : null}
        <Countries onChange = {handleChangeCountry} register = {register}/>
        {country !== null ? (
          <>
            <Invoice onChange = {neededInvoice} register = {register}/>
          </>
        ) : null}
        {errors.invoice && <span className = "fa visible failure message">Invoice is mandatory.</span>}
        {errors.country && <span className = "fa visible failure message">Country is mandatory.</span>}
        {invoice !== null ? (
          invoice ? (
            <>
              <label htmlFor="taxid">Insert your VAT ID</label>
              <input
                key="taxid"
                id="taxid"
                name="taxid"
                type="text"
                onChange={changeVatId}
                placeholder="e.g. EL1234567"
                ref={register({
                  required: true,
                  pattern: {
                    value: regexTaxId,
                    message: "invalid taxid",
                  },
                })}
              />
              {errors.taxid ? (
                <span className={"fa visible failure message"}>
                  {"Please fill up your VAT ID"}
                </span>
              ) : null}
            </>
          ) : null
        ) : null}
        <br></br>
        {visibleSkus === true ? <Skus area={area} initSubmit={initSubmit} parentCallback={passVariables} data={data}/>
        :
        <button
          type="button"
          onClick={handleNestedSubmit}
        >
          {"Calculate Cost"}
        </button>}
        {errorMessage ? (
          <span className={"fa visible failure message"}>
            Something happened: {errorMessage}.
          </span>
        ) : null}
        </form>
    </>
  )
}
export default ShopForm