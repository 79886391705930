import React, { useEffect, useState } from "react"
import { Sidebar, Tab } from "react-leaflet-sidetabs"
import { IconContext } from "react-icons"
import Home from "./Home"
import About from "./About"
import { FiHome, FiChevronRight, FiShoppingCart, FiInfo } from "react-icons/fi"
import ShopForm from "./ShopForm"
import ContactFormPanel from "./ContactFormPanel"

const Panel = ({ area, maxlat, minlat, maxlong, minlong }) => {
  const min_cost = parseInt(process.env.SMALL_AREA)
  const max_cost = parseInt(process.env.LARGE_AREA)
  const metersToHectares = parseInt(process.env.METERS2HECTARES)
  
  const smallArea = (min_cost / 15) * metersToHectares// Math.pow(10, parseInt(process.env.SMALL_AREA))
  const largeArea = (max_cost / 15) * parseInt(process.env.METERS2HECTARES) //Math.pow(10, parseInt(process.env.LARGE_AREA))
  const [collapsed, setCollapsed] = useState(true)
  const [selected, isSelected] = useState("home")
  const [glowing, isGlowing] = useState(null)

  const styleIcon = { color: "white", className: "global-class-name" }
  const styleIconHomeButton = { color: "white", className: "global-class-name-home" }
  const styleIconShopButton = { color: "white", className: "global-class-name-shop" }
  const areaMessage = area => {
    if (area <= 1000000) {
      return Number(area).toLocaleString("de-DE") + " m"
    } else {
      return (
        Number(area / 1000000)
          .toLocaleString("de-DE") + " km"
      )
    }
  }
  useEffect(() => {
    if (area && selected !== "shop") {
      isGlowing("shop")
    } else if (area === null) {
      isGlowing(null)
    }
  }, [isGlowing, area, selected])

  const areaText = area => {
    if (area) {
      switch (true) {
        case area <= smallArea:
          return (
            <>
              <p className="areasmall">
                The area that has been chosen is {areaMessage(area)}{" "}
                <sup>2</sup>
              </p>
              <p data-testid="wrong-area">Your area is too small, please, select a larger area.</p>
            </>
          )

        case area <= largeArea:
          return (
            <>
              <p data-testid="right-area">
                The area that has been chosen is {areaMessage(area)}
                <sup>2</sup>
              </p>
              <ShopForm
                area={area}
                maxlat={maxlat}
                minlat={minlat}
                maxlong={maxlong}
                minlong={minlong}
              />
            </>
          )
        case area > largeArea:
          return (
            <>
              <p data-testid="wrong-area-larger-area">
                The area that has been chosen is {areaMessage(area) }
                <sup>2</sup>.
              </p>
              <p>
                Your area is too large, it is better to contact us via the contact form beneath.
              </p>
                <ContactFormPanel
                maxlat={maxlat}
                minlat={minlat}
                maxlong={maxlong}
                minlong={minlong}/>
            </>
          )
        default:
          return <p>Please, select an area first, using the draw tool.</p>
      }
    } else {
      return <p data-testid="wrong-no-area">Please, select an area first, using the draw tool.</p>
    }
  }

  const onClose = () => {
    setCollapsed(true)
  }

  const onOpen = id => {
    isSelected(id)
    setCollapsed(false)
    if (id === "shop") {
      isGlowing(null)
    }
  }

  return (
    <Sidebar
      id="sidebar"
      position="right"
      collapsed={collapsed}
      closeIcon={<FiChevronRight />}
      selected={selected}
      glowing={glowing}
      onOpen={onOpen}
      onClose={onClose}
    >
      <Tab
        id="home"
        header="Home"
        icon={
          <IconContext.Provider value={styleIconHomeButton}>
            <FiHome />
          </IconContext.Provider>
        }
      >
        <Home/>
      </Tab>
      <Tab
        id="shop"
        header="Shop"
        icon={
          <IconContext.Provider value={styleIconShopButton}>
            <FiShoppingCart />
          </IconContext.Provider>
        }
      >
        {areaText(area)}
      </Tab>
      <Tab
        id="about"
        header="About"
        anchor="bottom"
        icon={
          <IconContext.Provider value={styleIcon}>
            <FiInfo />
          </IconContext.Provider>
        }
      >
        <About />
      </Tab>
    </Sidebar>
  )
}

export default Panel
