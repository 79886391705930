import React from "react"
import Layout from "../components/Layout"
import Map from "../components/App/Map"

const MapPage = () => {
  return (
    <>
      <Layout titlePage="App">
        <Map />
      </Layout>
    </>
  )
}
export default MapPage