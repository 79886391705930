import React, { useState, useEffect } from "react"
import { MapContainer, FeatureGroup } from "react-leaflet"
import { GoogleLayer } from "react-leaflet-google-v2"
import { EditControl } from "react-leaflet-draw"

import Panel from "./Sidepanel/Panel"
import Search from "../../utils/Search"
import L from "leaflet"
import loadable from '@loadable/component'

import { useSelector, useDispatch } from "react-redux"
import { TutorialExperienced } from "../../state/createStore.js"

const Tour = loadable(() => import('reactour'))

export const MapComponent = (props) => {
  const [ area , setarea] = useState(null)
  const [ maxlat , setmaxlat] = useState(null)
  const [ minlat , setminlat] = useState(null)
  const [ maxlong , setmaxlong] = useState(null)
  const [ minlong , setminlong] = useState(null)
  const [ isTourOpen, setIsTourOpen ] = useState(true);
  const dispatch = useDispatch()
  const TutorialLoader = useSelector(state => {
    return state.reducer.tutorial
  })
  useEffect(() => {
    if(isTourOpen === false) {
      dispatch(TutorialExperienced(false));
    }
    if(TutorialLoader === false)
    {
      setIsTourOpen(false);
    }
  },[isTourOpen, TutorialLoader, dispatch])
 const _onCreate = (e) => {
    //Maybe we should pass the bounds
    //https://esri.github.io/esri-leaflet/examples/zooming-to-all-features-2.html
    const type = e.layerType,
      layer = e.layer
    if (type === "rectangle") {
      const measurearea = L.GeometryUtil.geodesicArea(
        layer.getLatLngs()[0]
      ).toFixed(0)

      const extent = layer.getBounds()
      setarea(measurearea)
      setmaxlat(extent.getNorth().toFixed(4))
      setmaxlong(extent.getEast().toFixed(4))
      setminlat(extent.getSouth().toFixed(4),)
      setminlong(extent.getWest().toFixed(4))
    }
  }
 const _onDeleted = (e) => {
    setarea(null)
  }
  const { options } = props
  const position = [39.0742, 21.8243]
  const hydrid = "HYBRID"
  const steps = [
    {
      selector: '.leaflet-control-zoom-in',
      content: 'You can use "+" symbol to zoom in on the map and locate your area.',
    },
    {
      selector: '.leaflet-control-zoom-out',
      content: 'Or, you can use "-" symbol to zoom out on the map.',
    },
    {
      selector: '.leaflet-bar-part',
      content: 'Search easy your area by clicking on the magnifier and write your location you are looking for.',
    },
    {
      selector: '.leaflet-draw-draw-rectangle',
      content: 'Then click on the draw control to create the bounds of your area.',
    },
    {
      selector: '.leaflet-draw-edit-remove',
      content: 'If you did not select your area correct, you can delete the rectangle.',
    },
    
    {
      selector: '.global-class-name-shop',
      content: 'Here is placed your order.',
    },
  ];
  
  return (
    <div>
      {typeof window !== "undefined" ? (
        <>
          <Tour
            steps={steps}
            isOpen={isTourOpen}
            onRequestClose={() => setIsTourOpen(false)}
            className="color-of-text"
            accentColor="#1cb495"
          />
          <MapContainer
            {...options}
            center={position}
            zoom={7}
            zoomControl={true}
            className={"mapStyle"}
          >
            <GoogleLayer
              googlekey={process.env.GOOGLE_MAPS_API_KEY}
              maptype={hydrid}
            />
            <Search />
            <FeatureGroup>
              <EditControl
                position="topleft"
                onCreated={_onCreate}
                onDeleted={_onDeleted}
                draw={{
                  rectangle: {
                    showArea: true,
                    showRadius: false,
                    feet: false,
                    metric: ["km", "m"],
                    nautic: false,
                  },
                  polygon: false,
                  polyline: false,
                  circle: false,
                  marker: false,
                  circlemarker: false,
                }}
                edit={{
                  featureGroup: FeatureGroup, //REQUIRED!!
                  edit: false,
                }}
              />
            </FeatureGroup>
          </MapContainer>
          <Panel
            area={area}
            maxlat={maxlat}
            minlat={minlat}
            maxlong={maxlong}
            minlong={minlong}
          />
        </>
      ) : null}
    </div>
  )
}
export default MapComponent
