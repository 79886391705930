/* eslint-disable no-useless-escape */
import { Link } from "gatsby"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import axios from "axios"
import Codes from "../../Common/Codes"

export default function ContactFormPanel({ maxlat, minlat, maxlong, minlong }) {
    const [checked, setChecked] = useState(false);
    const [firstName, setName] = useState("");
    const [surName, setSurName] = useState("");
    const [company, setCompany] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [code, setCode] = useState("");
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const { handleSubmit, register, errors} = useForm();
    const url = process.env.FRONTEND_HOST_LARGE_AREA + "/contact_sales"
    const handleChangeFirstName = (event) => {
        setName(event.target.value);  
    }
    const handleChangeSurname = (event) => {
        setSurName(event.target.value);
    }
    const handleChangeCompany = (event) => {
        setCompany(event.target.value);
    }
    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    }
    const handleChangeCode = (text) => {
        setCode(text);
    }
    const handleChangeTelephone = (event) => {
        setTelephone(event.target.value);
    }
    const handleChangeMessage = (event) => {
        setMessage(event.target.value);
    }
    const onSubmit = () => {
        if(errorMessage === null || errorMessage === true)
        {
            const data = {
                firstName: firstName,
                surname: surName,
                company: company,
                email: email,
                telephone: code + telephone,
                message: message,
                maxlat: maxlat,
                minlat: minlat,
                maxlong: maxlong,
                minlong: minlong,
                timestamp: Date.now().toString()
            }
            axios.post(url, data)
            .then(response => {
                if(response.status === 200)
                {
                    setErrorMessage(false);
                }
                else if(response.status === 404)
                {
                    setErrorMessage(true);
                }
                })
            .catch(err => {
                setErrorMessage(true);
            });
        }
    }
    return (
        <div className="parent-contact-form"> 
            <form onSubmit={handleSubmit(onSubmit)} className="resize container is-max-desktop">
                <div className="field">
                    <label htmlFor="firstName" className="label">First Name</label>
                    <div className="control has-icons-left ">
                        <input id="firstName" name = "username" className="input" type="text" onChange={handleChangeFirstName} placeholder="First Name" ref={register({required: true, minLength: 3})}></input>
                        <span className="icon is-small is-left">
                        <i className="fa fa-user"></i>
                        </span>
                    </div>
                    {errors.username && errors.username.type === "required" && <span className = "fa visible failure message">First name is mandatory.</span>}
                    {errors.username && errors.username.type === "minLength" && <span className = "fa visible failure message">First name must be at least 3 characters.</span>}
                </div>
                <div className="field">
                    <label htmlFor="surname" className="label">Surname</label>
                    <div className="control has-icons-left ">
                        <input id="surname" name="surname" className="input" type="text" onChange={handleChangeSurname} placeholder="Surname" ref={register({required: true, minLength: 3})}></input>
                        <span className="icon is-small is-left">
                        <i className="fa fa-user"></i>
                        </span>
                    </div>
                    {errors.surname && errors.surname.type === "required" && <span className = "fa visible failure message">Surname is mandatory.</span>}
                    {errors.surname && errors.surname.type === "minLength" && <span className = "fa visible failure message">Surname must be at least 3 characters.</span>}
                </div>
                <div className="field">
                    <label htmlFor="company" className="label">Company</label>
                    <div className="control has-icons-left ">
                        <input id="company" name="company" className="input" type="text" onChange={handleChangeCompany} placeholder="Company" ref={register({required: true, minLength: 3})}></input>
                        <span className="icon is-small is-left">
                        <i className="fa fa-building"></i>
                        </span>
                        <span className="icon is-small is-right"> 
                        </span>
                    </div>
                    {errors.company && errors.company.type === "required" && <span className = "fa visible failure message">Company name is mandatory.</span>}
                    {errors.company && errors.company.type === "minLength" && <span className = "fa visible failure message">Company name must be at least 3 characters.</span>}
                </div>
                <div className="field">
                    <label htmlFor="email" className="label">Email</label>
                    <div className="control has-icons-left ">
                        <input id="email" name="emailAddress" className="input" type="email" onChange={handleChangeEmail} placeholder="Email" ref={register({required: true, pattern: {value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i, message: "Email is not valid.",},})}></input>
                        <span className="icon is-small is-left">
                        <i className="fa fa-envelope"></i>
                        </span>
                    </div>
                    {errors.emailAddress && errors.emailAddress.type === "required" && <span className = "fa visible failure message">Email is mandatory.</span>}
                    {errors.emailAddress && errors.emailAddress.message === "Email is not valid." && <span className = "fa visible failure message">{errors.emailAddress.message}</span>}
                </div>
                <div className="field">
                    <label htmlFor="telephone" className="label">Business telephone</label>
                    <div className="code-form">
                        <Codes onChange = {handleChangeCode} register = {register}/>
                    <div className="control has-icons-left">
                        <input id="telephone" name="businessTelephone" className="input" type="text" onChange={handleChangeTelephone} placeholder="Business telephone" ref={register({required: true, pattern: {value: /^[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, message: "Business telephone is not valid.",},})}></input>
                        <span className="icon is-small is-left">
                        <i className="fa fa-tty"></i>
                        </span>
                    </div>
                    </div>
                    {errors.code && <span className = "fa visible failure message">Business telephone code is mandatory.</span>}
                    {errors.businessTelephone && errors.businessTelephone.type === "required" && <span className = "fa visible failure message">Business telephone is mandatory.</span>}
                    {errors.businessTelephone && errors.businessTelephone.message === "Business telephone is not valid." && <span className = "fa visible failure message">{errors.businessTelephone.message}</span>}
                </div>
                <div className="field">
                    <label htmlFor="message" className="label">Message</label>
                    <div className="control">
                        <textarea id="message" className="text" name="text" onChange={handleChangeMessage} placeholder="Message" ref={register({required: true, pattern: {value: /^.{1,1000}$/, message: "Message is mandatory and must be shorter than 1000 characters.",},})}></textarea>
                    </div>
                    {errors.text && errors.text.type === "required" && <span className = "fa visible failure message">Message is mandatory.</span>}
                    {errors.text && errors.text.message === "Message is mandatory and must be shorter than 1000 characters." && <span className = "fa visible failure message">{errors.text.message}</span>}
                </div>
                <div className="color-condition">
                    <div className="checkbox-center">
                        <input checked={checked} onChange={() => setChecked(!checked)} id="terms" name="checkbox" type="checkbox" ref={register({ required: true })}/>
                        <div className="position">
                            &nbsp;&nbsp;<label htmlFor="terms">I agree to the <Link to="/terms">terms and conditions.</Link></label>
                        </div>
                    </div>
                </div>
                {errors.checkbox && <span className = "fa visible failure message">Please, accept the terms and conditions.</span>}
                <br></br>
                <div className="field is-grouped">
                    <div className="control position-message">
                    <input className={errorMessage === null || errorMessage === true ? "active" : "disabled"} type="submit" value="Send"/>
                    {errorMessage === false ?
                        <div className="appear-positive">
                            <h4>Thank you for your preference in our services, your request has been successfully submitted.</h4>
                        </div>:
                    null}
                    </div>
                    {errorMessage === true ?
                        <div className="appear-negative">
                            <h4>Something went wrong, please try again later.</h4>
                        </div>:
                    null}
                </div>
            </form>
        </div>
  )
}