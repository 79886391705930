/* eslint-disable jsx-a11y/no-onchange */
import React from "react"

const Invoice = ({ onChange, register }) => {
  const selectInvoice = (event) => {
    const text = event.target.value;
    onChange(text);
}
  return (
    <>
      <label htmlFor="invoice">Do you need an invoice?</label>
      <select
        id="invoice"
        name="invoice"
        onChange={selectInvoice}
        defaultValue={" -- Options -- "}
        ref={register({required: true, pattern: {value: /^[^-]+$/i},})}
      >
        <option key="other" value=" -- Options -- " disabled>
          {" "}
          -- Options --{" "}
        </option>
        <option value={true}>Yes</option>
        <option value={false}>No</option>
      </select>
    </>
  )
}
export default Invoice