import React from "react"

const Home = () => {
  return (
    <div className="custom-text-sl">
      <h3>Poles on Earth</h3>
      <p>
        <b>Poles on Earth</b> is an AI web service that provides the positions
        of utility poles everywhere on Earth using satellite imagery.
      </p>
      <p>
        <b>Features:</b>
      </p>
      <ul>
        <li>Locate utility poles as close to real-time as possible.</li>
        <li>Classify to electricity, telecommunication, lighting poles.</li>
        <li>Locate utility poles in a specific time period.</li>
      </ul>

      <p>
        <b>Instructions:</b>
      </p>
      <ol>
        <li>
          Locate your area of interset by zoom in and pan to the map, or use the
          search but to loate a specific area.
        </li>
        <li>
          Use the rectangle button draw a rectangle in the area of interest.
        </li>
        <li>
          Go to the shop tab (right-side panel) and choose the types of utility
          poles to be discovered.
        </li>
        <li>Click on the "Buy" button to pay for the value of the service.</li>
      </ol>

      <p>
        <b>Utility poles classes:</b>
      </p>
      <ul>
        <li>
          Electricity poles are all the poles that are connected with
          electricity wires, usually are higher than 40 ft(12 m).{" "}
        </li>
        <li>
          Telecommunication poles are utility poles that carry overhead
          telecommunication lines, usually 8 m height.{" "}
        </li>
        <li>
          Lighting poles are poles used for lighting space areas that are
          installed, with carring any wires.
        </li>
      </ul>
    </div>
  )
}

export default Home
