import React from "react";
import { SearchControl, OpenStreetMapProvider } from "react-leaflet-geosearch";
import L from "leaflet"

const GeoSearch = () => {
    const prov = OpenStreetMapProvider();
    const GeoSearchControlElement = SearchControl;

    const props = {
      provider: prov,
      showMarker: true,
      showPopup: false,
      style: "button",
      marker: {
        icon: new L.icon({
          iconUrl: "../marker.png",
          iconSize: [30, 30],
          iconAnchor: [15, 30],
          popupAnchor: [0, -25],
        }),
        draggable: false,
      },
      popupFormat: ({ query, result }) => result.label,
      maxMarkers: 4,
      retainZoomLevel: false,
      animateZoom: true,
      autoClose: true,
      searchLabel: "Enter address",
      keepResult: true,
}

    return (
        <GeoSearchControlElement
          {...props}
        />
    );
}
export default GeoSearch