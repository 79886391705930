/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from "react"

const Skus = ({ initSubmit, parentCallback, data}) => {

  const [items, setItems] = useState({ skus: [], totalPrice: 0 })
  const formatPrice = price => {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(price / 100)
  }
  useEffect(() => {
    parentCallback(items);
  }, [items])
  const handleCheckboxChange = ({ target }) => {
    const sku = JSON.parse(decodeURIComponent(target.value))
    if (target.checked) {
      setItems({
        skus: [
          ...items.skus,
          {
            name: target.name,
            currency: sku.currency,
            quantity: sku.quantity,
            amount: sku.price,
            image: sku.image,
          },
        ],
        totalPrice: items.totalPrice + (sku.price * sku.quantity),
      })
    } else {
      setItems({
        skus: items.skus.filter(el => {
          return el.name !== target.name
        }),
        totalPrice: items.totalPrice - (sku.price * sku.quantity),
      })
    }
  }

  return (
    <>
      {data !== null ? <div>Select poles categories </div>: null}
      {data !== null
      ?
          data.skus.map(( responseData ) => {
            const inputValue = encodeURIComponent(
              JSON.stringify({
                price: responseData.price,
                image: responseData.image,
                currency: responseData.currency,
                quantity: responseData.quantity
              })
            )
            return (
              <div key={"div-" + responseData.id} className={"column"}>
                <input
                  type="checkbox"
                  key={responseData.id}
                  id={responseData.id}
                  name={responseData.name}
                  value={inputValue}
                  onClick={handleCheckboxChange}
                />
                <label htmlFor={responseData.id}>
                  {" "}
                  {responseData.name} <br />
                  <img className="images-size" src={responseData.image} alt="Pole Images" width="75" height="75"/>
                  <br />
                  Price: {formatPrice((responseData.price *  responseData.quantity))}
                </label>
              </div>
            )
          })   
        : null}
        {data!== null ? (
          <p>Please, check a category from above</p>
        ) : null}
        {items.totalPrice > 0 && data!== null ? (
          <p>The total price is: {formatPrice(items.totalPrice)}</p>
        ) : null}
        {data!== null ?
        <button
          type="submit"
          className={
            items.skus.length > 0 && !initSubmit ? "active" : "disabled"
          }
        >
          {!initSubmit ? "Purchase" : "Processing..."}
        </button>
        : null}
    </>
  )
}
export default Skus